import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const DirectionsHouseOfWorship = ({ data }) => (
    <InnerPage site="hda" title="Directions to the House of Worship">
        <IntroHolder
            title="Directions to the House of Worship"
            picture={data.introImage}
        />

        <TwoColumns>
            <Content>
                <h2>Address</h2>
                <div>
                    <p>
                        Bahá’í-Haus der Andacht
                        <br />
                        Eppsteiner Str. 95
                        <br />
                        D-65719 Hofheim-Langenhain
                        <br />
                        Tel: 06192 – 901677
                        <br />
                        Email: <a href="mailto:biz@bahai.de">biz@bahai.de</a>
                    </p>
                </div>
                <h2>House of Worship Hours</h2>
                <p>Monday to Sundays: 9am – 8pm</p>
                <h2>Visitors' Centre & International Bahá'í Bookstore Hours</h2>
                <p>Closed, given the current regulation</p>
                <h2>Directions</h2>
                <div className="google-maps">
                    <iframe
                        title="House of Worship map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d823.7821055032554!2d8.396287458584288!3d50.11314740700387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bda373f8a0fced%3A0x39119dfb20bc1489!2sBah%C3%A1'%C3%AD+Haus+der+Andacht+-+europ%C3%A4isches+Gebetshaus+der+Bah%C3%A1'%C3%AD-Religion!5e0!3m2!1sde!2sus!4v1488364610971"
                        width="600"
                        height="450"
                        frameborder="0"
                        allowfullscreen="allowfullscreen"
                    />
                </div>
                <h3>By car</h3>
                <h4>From Frankfurt/Main</h4>
                <div>
                    <p>
                        Take Autobahn A66 in the direction of Wiesbaden. Exit at
                        either the „Hattersheim/Kriftel“ or the „Diedenbergen“
                        exit to Hofheim and follow the signs to Langenhain.
                        (Written either as Langenhain or Hofheim-Langenhain).
                    </p>
                </div>
                <h4>From Frankfurt Airport</h4>
                <div>
                    <p>
                        Take Autobahn A43 to the A66 (direction Wiesbaden) and
                        continue as above.
                    </p>
                </div>
                <h4>From Köln (Cologne)</h4>
                <div>
                    <p>
                        Exit Autobahn A3 on to A66 at the Wiesbadener Kreuz in
                        the direction of Frankfurt. Exit A66 at „Diedenbergen“
                        and follow the signs to Langenhain.
                    </p>
                </div>
                <h3>By Public Transport</h3>
                <h4>
                    From the central train station in Frankfurt — Frankfurt
                    (Main) Hauptbahnhof
                </h4>
                <div>
                    <p>
                        Walk to underground station („Tiefbahnhof“) and take the
                        S2 suburban train in the „Niedernhausen“ direction. Get
                        out at „Hofheim“ station (the stop after „Kriftel“).
                        From Hofheim, take bus number 403 in the „Wildsachsen“
                        direction. Get out at the „Langenhain Siedlung“ stop.
                        Walk about 20 m (further in the direction the bus is
                        going) to the T-junction and turn right on to Eppsteiner
                        Str. The House of Worship is a 5 minute walk from the
                        bus stop. A train ticket from Frankfurt to Hofheim costs
                        about 3.00€. This ticket is also valid on the bus from
                        Hofheim to Langenhain. In the evening and on Sundays
                        when the bus does not run, a shuttle taxi takes people
                        from the train station Hofheim to Langenhain (about
                        1.50€ for every person). A normal taxi from Hofheim to
                        Langenhain costs about 10.00€.
                    </p>
                </div>
                <h4>From the Airport</h4>
                <div>
                    <p>
                        Take a train to Frankfurt central station and follow the
                        directions as above. A taxi from the airport to the
                        House of Worship costs about 40.00€.
                    </p>
                </div>
                <h3>Via hiking path</h3>
                <iframe
                    title="House of Worship hiking path"
                    src="https://www.outdooractive.com/de/route/wanderung/taunus/rundweg-in-das-thierbachtal/100269105/embed.html?flexView=false"
                    width="100%"
                    height="472px"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                />
            </Content>
        </TwoColumns>
    </InnerPage>
);

export default DirectionsHouseOfWorship;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "hda-anreise-feature.jpg" }) {
            ...fluidImage
        }
    }
`;
